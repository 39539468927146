<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Listing Generator 🏠</h1>
      </div>
    </div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="row row-split-content">
          <div class="content-width-large">
            <div class="form" ms-signup="true">
              <div class="form-group">
                <label>Address</label>
                <input
                  v-model="address"
                  class="form-input text-input w-input"
                />
              </div>
              <div class="w-layout-grid form-grid-halves">
              <div class="form-group">
                <label>City</label>
                <input v-model="city" class="form-input text-input w-input" />
              </div>
              <div class="form-group">
                <label>State</label>
                <input v-model="state" class="form-input text-input w-input" />
              </div>
              </div>
              <div class="w-layout-grid form-grid-thirds">
              <div class="form-group">
                <label>Bedrooms</label>
                <input
                  v-model.number="bedrooms"
                  type="number"
                  class="form-input text-input w-input"
                />
              </div>
              <div class="form-group">
                <label>Bathrooms</label>
                <input
                  v-model.number="bathrooms"
                  type="number"
                  class="form-input text-input w-input"
                />
              </div>

              <div class="form-group">
                <label>Square Footage</label>
                <input
                  v-model.number="sqft"
                  type="number"
                  class="form-input text-input w-input"
                />
              </div>
              </div>
              <div class="form-group">
                <label>Briefly describe the listing</label>
                <textarea
                  v-model="description"
                  class="form-input text-area-input w-input"
                >
                </textarea>
              </div>
              <div class="w-layout-grid form-grid-halves">
              <div class="form-group">
                <label>Tone</label>
                <select v-model="tone" class="form-input w-input droppy">
                  <option value="casual">Casual</option>
                  <option value="friendly">Friendly</option>
                  <option value="professional">Professional</option>
                </select>
              </div>
              <div class="form-group">
                <label>Length</label>
                <select v-model="length" class="form-input w-input droppy">
                  <option value="short">Short</option>
                  <option value="medium">Medium</option>
                  <option value="long">Long</option>
                </select>
              </div>
              </div>
              <button class="button w-button" v-show="generateBtn" v-on:click="generate">
                Generate
              </button>
            </div>
          </div>
          <div class="content-width-large">
    
            <div v-show="isLoading">
                <div class="loader"></div>
            </div>
            <div v-show="listingReady" class="post-description-container">
                      <h3>Listing Description</h3>
              <textarea
                v-model="listingOutput"
                ref="listingOutputDescription"
                class="form-input text-area-input w-input listing-description"
              >
              </textarea>
              <div class="icon-row">
                <div class="icon-container">
                  <span class="ai-icon" v-on:click="generate"
                    ><i class="fas fa-magic"></i
                  ></span>
                  <span class="copy-text" v-on:click="copy"
                    ><i class="fas fa-copy"></i
                  ></span>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import { aiService } from "@/services";
export default {
  name: "home",
  components: {
    Navigation,
  },
  data() {
    return {
      description: "",
      length: "medium",
      tone: "friendly",
      listingOutput: "",
      address: "",
      city: "",
      state: "",
      bedrooms: 0,
      bathrooms: 0,
      sqft: 0,
      isLoading: false,
      listingReady: true,
      generateBtn: true,
    };
  },
  created() {},
  methods: {
    generate() {
      this.generateBtn = false;
      this.isLoading= true;
      this.listingReady = true;
      var listing = {};
      listing.tone = this.tone;
      listing.description = this.description;
      listing.length = this.length;
      (listing.city = this.city),
        (listing.state = this.state),
        (listing.bedrooms = this.bedrooms),
        (listing.bathrooms = this.bathrooms);
      listing.sqft = this.sqft;
      console.log(listing);
      aiService.generateListing(listing).then((data) => {
        var post = data;
        this.listingOutput = post;
        this.isLoading = false;
        this.listingReady = true;
      });
    },

    copy() {
      console.log(this.listingOutput);
      this.copyText(this.$refs.listingOutputDescription); //Custom mixin. Ref is defined in the element e.g. <div ref="text">
      this.$swal.fire({
        title: "Text Copied to Clipboard",
        timer: 1200,
        icon: "success",
        toast: true,
      });
    },
  },
};
</script>
<style scoped>
.droppy {
  cursor: pointer;
}
.ai-icon {
  cursor: pointer;
  padding: 5px;
}
.copy-text {
  cursor: pointer;
  padding: 5px;
}
.listing-description {
  height: 515px;
  margin-left: 10px;
}
/* HTML: <div class="loader"></div> */
.loader {
  margin-top: 10px;
  margin-left: 180px;
  width: 250px;
  height: 250px;
  position: relative;
  --c:no-repeat linear-gradient(#25b09b 0 0);
  background:
    var(--c) center/100% 100px,
    var(--c) center/100px 100%;
}
.loader:before {
  content:'';
  position: absolute;
  inset: 0;
  background:
    var(--c) 0    0,
    var(--c) 100% 0,
    var(--c) 0    100%,
    var(--c) 100% 100%;
  background-size: 105.5px 105.5px;
  animation: l16 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l16 {
   33%  {inset:-100px;transform: rotate(0deg)}
   66%  {inset:-100px;transform: rotate(90deg)}
   100% {inset:0    ;transform: rotate(90deg)}
}
</style>
